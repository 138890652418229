import React,{Fragment} from 'react';
import {Link} from "react-router-dom";

const Logo = ({sideHeader}) => {
    return (
        <Fragment>
            <Link to="/">
                    <h3>SHAINCODES</h3>
                </Link>
        </Fragment>
    );
};

export default Logo;
