import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Content from "../../components/content";
import aboutData from '../../data/about-me'

const AboutMe = ({type}) => {
    return (
        <div>
            <Container>
                <Row className={'align-items-center'}>
                    <Col>
                        <Content classes="about-content">
                            <h3 className="block-title">ABOUT ME</h3>
                            <p>Currently a Blockchain/Full-Stack Developer at <a href="https://www.ydylife.com/" target="_blank"><b>YDY</b></a> and a Mobile Software Engineer at <a href="https://www.influur.com/" target="_blank"><b>Influur</b></a></p>
                            <p>AI Side Quests (OpenAI & LangChain): <a href="https://twitter.com/EmailMeSummary" target="_blank"><b>EmailMeSummary Twitter Bot</b></a> and <a href="https://langchain-web-browser.vercel.app/" target="_blank"><b>The Ultimate Web Browser Tool</b></a></p>
                            <p>Blockchain Side Quests: <a href="https://dalleonflow.art/" target="_blank"><b>DalleOnFlow</b></a> and <a href="https://www.buildonflow.art/" target="_blank"><b>BuildOnFlowBanner</b></a></p>
                            <p className="mb-50">Previously a Full-Stack Software Engineer at a Stealth Startup, Software Engineer at Synthesis School, and Software Engineer Intern at The Boring Company, NASA, Amazon, and Microsoft</p>
                            {/* <h3 className="block-title">Skills</h3>
                            <div className="skill-wrap">
                                <ul className="personal-info">
                                    <li><span>Programming Languages:</span>JavaScript, TypeScript, Python, Java, Cadence, Dart, HTML/CSS</li>
                                    <li><span>Frameworks:</span>React.js, Next.js, Node.js, Express.js, React Native, Django</li>
                                    <li><span>Databases/QL:</span>Supabase, GraphQL, SQL, Firebase, MongoDB</li>
                                    <li><span>Tools:</span>Git/GitHub, Visual Studio Code, XCode, Android Studio, Anaconda, Bitbucket, Trello, Figma, Zeplin, Jira</li>
                                </ul>
                            </div> */}
                        </Content>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutMe;