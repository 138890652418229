import React from 'react';
import HeroBannerContainer from "../../../container/HeroBanner";
import personalInfo from '../../../data/personalInfo';
import {Container} from 'react-bootstrap';

const BannerFreelancerPortfolio = () => {
    return (
        <div className="section main-slider-1">
        <Container>
            <div className="text-center">
                <div className="slider-banner-info text-center">
                    <h2 className="slider-banner-title">Hi There, I’m <b>{personalInfo.name}</b></h2>
                    <h5 className="slider-banner-text">{personalInfo.designation}</h5>
                </div>
            </div>
        </Container>
        </div>
    );
};

export default BannerFreelancerPortfolio;