import React, {Fragment} from 'react';
import Switcher from "./components/Switcher";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

/*
* @ Component Imported
* */
import ErrorPage from "./pages/404Error";
import HomePage from "./pages/home/HomePage";
import BlogDetailsPage from "./pages/blog/BlogDetails";
import BlogThreeColumnPage from "./pages/blog/ThreeColumn";
import PortfolioGridThreeColumnFullWidth from "./pages/portfolio/grid/three-column-fullwidth";
import PortfolioGridThreeColumnPage from "./pages/portfolio/grid/three-column";
import BlogDetailsLeftSidebarPage from "./pages/blog/BlogDetailsLeftSidebar";
const App = () => {
    return (
        <Fragment>
            <Switcher/>
            <Router>
                <Switch>
                    {/* <Route path="/blog-posts"
                           component={BlogThreeColumnPage}/> */}
                    <Route exact path="/"
                           component={HomePage}
                    />
                    <Route path="/builds"
                           component={BlogThreeColumnPage}/>
                    <Route exact component={ErrorPage}/>
                </Switch>
            </Router>
        </Fragment>
    );
};

export default App;