import React from 'react';
import {Link} from 'react-router-dom';

const Blog = ({title, url, thumb, excerpt, postDate, socialIcons}) => {
    return (
        <div className="blog-item">
            <div className="blog-image">
                <a href={url} target="_blank">
                    <img src={require('../../assets/images/blog/' + thumb)} alt={title} />
                </a>
            </div>

            <div className="blog-content">
                <h4 className="blog-title">
                    <a href={url} target="_blank">{title}</a>
                </h4>

                <ul className="blog-meta">
                    <li>{postDate}</li>
                </ul>

                <div className="blog-desc">
                    <p>{excerpt}</p>
                </div>

                {/* <div className="read-more">
                    <a href={url} target="_blank">READ MORE...</a>
                </div> */}

                                    {socialIcons.map((icon, i) => (
                                        <a key={i} href={icon.url} target="_blank">
                                            <i className={`fa fa-${icon.media}`} style={{fontSize: '25px', marginRight: '5px' }}/>
                                        </a>
                                    ))}
            </div>

        </div>
    );
};

export default Blog;